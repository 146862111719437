import React from "react"
import Link from "gatsby-link"

function BackLink({ origin }) {
  let url
  let text
  switch (origin) {
    case "blog":
      url = "/blogs"
      text = "Blogs"
      break
    case "article":
      url = "/articles"
      text = "Articles"
      break
    case "story":
      url = "/stories"
      text = "Stories"
      break
    case "location":
      url = "/locations"
      text = "Locations"
      break
    default:
      url = "/"
      text = "Homepage"
  }
  return (
    <div>
      <Link to={url}>Go back to the {text}</Link>
    </div>
  )
}

export default BackLink
