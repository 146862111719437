import React from "react"
import Layout from "../components/layout"
import BackLink from "../components/backlink"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import mapboxgl from "mapbox-gl"

class SubPage extends React.Component {
  createMap() {
    const { data } = this.props
    const location = data.contentfulLocations
    var zoom = 14
    if (location.singleZoomLevel != null) {
      zoom = location.singleZoomLevel
      console.log(location.singleZoomLevel)
    }

    const coordinates = [location.geocode.lon, location.geocode.lat]
    const map = new mapboxgl.Map({
      center: coordinates,
      container: "map",
      style: "mapbox://styles/mapbox/streets-v9",
      zoom: zoom,
    })

    map.on("load", function () {
      map.addLayer({
        id: "Places I have Been",
        type: "circle",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                title: location.title,
                properties: {
                  title: location.title,
                  description: location.title,
                  slug: location.slug,
                },
                geometry: {
                  type: "Point",
                  coordinates: [location.geocode.lon, location.geocode.lat],
                },
              },
            ],
          },
        },
        paint: {
          "circle-radius": 7,
          "circle-color": "#007cbf",
          "circle-opacity": 0.8,
          "circle-stroke-width": 0,
        },
      })
    })
    map.addControl(new mapboxgl.NavigationControl())
  }

  componentDidMount() {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic2VyaXRoIiwiYSI6ImNqcGI1eXN4dDBtcWwzcXAyNGsycG0xaWgifQ.Qg3PAYHQ72Qem7VbWCb0ZQ"
    this.createMap()
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    const location = this.props.data.contentfulLocations
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title={location.title}
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div>
          <h1>{location.title}</h1>

          <div className="mapContainer">
            <div id="map" className="map"></div>
          </div>

          <BackLink origin="location" />
        </div>
      </Layout>
    )
  }
}

export default SubPage

export const locationQuery = graphql`
  query locationQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulLocations(slug: { eq: $slug }) {
      id
      title
      geocode {
        lon
        lat
      }
      singleZoomLevel
    }
  }
`
